import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
  return (
    <Layout pageTitle="About Me">
      <p>For a good time call: 604-401-4643</p>
    </Layout>
  )
}

export default AboutPage
